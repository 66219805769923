import * as React from 'react';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';
import {
  CmdInput,
  CmdLabel,
  CmdModalFooterButton,
  CmdSimpleSelect,
  CmdSubpage,
  CmdTextarea,
} from '@commander-services/gui-components';
import CmdBox, { CmdBoxButton } from '@commander-services/cmd-box';
import { Controller, useForm } from 'react-hook-form';
import PermissionsService, {
  PERMISSION_PERIOD_LOCK_ADMIN,
} from '../../Services/PermissionsService';
import configJs from '../../../config';
import { TABLE_REFUELING } from '../../Services/TableService';
import { IOption } from '../Forms/CmdField';
import usePageWithForm from '../../hooks/usePageWithForm';
import OnlineHelp from '../OnlineHelp';
import { convertDateInRides, getLabelValue, validateFields } from './RefuelingService';
import { VehiclesBasicInfo, VehiclesBasicInfoItem } from '../Vehicles/types';
import Calendar from '../Forms/ReactHookFormFields/Calendar';
import CmdUploadHookForm from '../Forms/CmdUploadHookForm';
import {
  CurrencyIdApiResponse,
  RefuelingApiResponse,
  RefuelingsFormValues,
  VatApiResponse,
} from './types';
import { CmdForm } from '../Forms/types';
import { CAN_SOURCE, CanRefuelingDataToConvert } from './CanRefuelings/types';
import { selectedVehiclesAtom } from '../../store/recoil/vehicles';
import useAnalytics from '../../hooks/useAnalytics';

type RefuelingFormAdditonalData = {
  locked?: string;
  getRideIdOptions: (
    customerId: number,
    vehicleId: number,
    accountingTs?: string
  ) => Promise<IOption[] | false>;
  getCurrencyIdOption: (customerId: number) => Promise<CurrencyIdApiResponse | false>;
  getFuelTypeIdOptions: (customerId: number) => Promise<IOption[] | false>;
  getGasStationNetworkIdOptions: (customerId: number) => Promise<IOption[] | false>;
  getSecondaryCurrencyIdOptions: (customerId: number) => Promise<IOption[] | false>;
  vehicles: VehiclesBasicInfo;
  getVatOption: (customerId: number) => Promise<VatApiResponse | false>;
};

type RefuelingFormProps = CmdForm<
  RefuelingApiResponse | CanRefuelingDataToConvert,
  RefuelingsFormValues,
  RefuelingFormAdditonalData
>;

export default function RefuelingForm(props: RefuelingFormProps): JSX.Element {
  usePageWithForm();
  const { formatMessage: f } = useIntl();
  const { id } = props.data || {};
  const [isLoaderActive, setIsLoaderActive] = React.useState<boolean>(false);
  const [isSecondaryCurrency, setIsSecondaryCurrency] = React.useState<boolean>(
    !!props.data?.secondaryCurrencyId
  );
  const selectedVehicles = useRecoilValue<number[]>(selectedVehiclesAtom);
  const [rideIdOptions, setRideIdOptions] = React.useState<IOption[]>([]);
  const [fuelTypeIdOptions, setFuelTypeIdOptions] = React.useState<IOption[]>([]);
  const [gasStationNetworkIdOptions, setGasStationNetworkIdOptions] = React.useState<IOption[]>([]);
  const [secondaryCurrencyIdOptions, setSecondaryCurrencyIdOptions] = React.useState<IOption[]>([]);
  const [currencyIdOption, setCurrencyIdOption] = React.useState<{ [currencyId: number]: string }>(
    {}
  );
  const [showEditPriceForm, setShowEditPriceForm] = React.useState<boolean>(false);
  const [showEditCurrencyForm, setShowEditCurrencyForm] = React.useState<boolean>(false);
  const { trackEvent } = useAnalytics();

  const allVehicles = React.useMemo(() => {
    return props.additionalData?.vehicles || {};
  }, [props.additionalData?.vehicles]);

  const vehicleIdOptions = React.useMemo(() => {
    const options: IOption[] = Object.values(allVehicles).map((vehicle: VehiclesBasicInfoItem) => ({
      value: String(vehicle.id),
      item: vehicle.name,
      label: vehicle.licenceNumber,
    }));
    return options;
  }, [allVehicles]);

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    getValues,
    clearErrors,
    watch,
    formState: { errors, touchedFields },
  } = useForm<RefuelingsFormValues>({
    mode: 'onChange',
    defaultValues: {
      accountingTs: props.data?.accountingTs || new Date(),
      vehicleId: props.data?.vehicleId ? String(props.data.vehicleId) : '',
      rideId: props.data?.rideId ? String(props.data.rideId) : '',
      gasStationNetworkId: props.data?.gasStationNetworkId
        ? String(props.data.gasStationNetworkId)
        : '',
      fuelTypeId: props.data?.fuelTypeId ? String(props.data.fuelTypeId) : '',
      amount: props.data?.amount ? String(props.data.amount) : '',
      vat: props.data?.vat ? String(props.data.vat) : '',
      unitPriceIncVat: props.data?.unitPriceIncVat ? String(props.data.unitPriceIncVat) : '',
      unitPrice: props.data?.unitPrice ? String(props.data.unitPrice) : '',
      price: props.data?.price ? String(props.data.price) : '',
      vatPrice: props.data?.vatPrice ? String(props.data.vatPrice) : '',
      priceIncVat: props.data?.priceIncVat ? String(props.data.priceIncVat) : '',
      currencyId: props.data?.currencyId ? String(props.data.currencyId) : '',
      billId: props.data?.billId ? String(props.data.billId) : '',
      note: props.data?.note || '',
      attachments: props.data?.attachments || [],
      secondaryCurrencyId: props.data?.secondaryCurrencyId
        ? String(props.data.secondaryCurrencyId)
        : '',
      secondaryVat: props.data?.secondaryVat ? String(props.data.secondaryVat) : '',
      secondaryUnitPriceIncVat: props.data?.secondaryUnitPriceIncVat
        ? String(props.data.secondaryUnitPriceIncVat)
        : '',
      secondaryUnitPrice: props.data?.secondaryUnitPrice
        ? String(props.data.secondaryUnitPrice)
        : '',
      secondaryPrice: props.data?.secondaryPrice ? String(props.data.secondaryPrice) : '',
      secondaryVatPrice: props.data?.secondaryVatPrice ? String(props.data.secondaryVatPrice) : '',
      secondaryPriceIncVat: props.data?.secondaryPriceIncVat
        ? String(props.data.secondaryPriceIncVat)
        : '',
    },
    resolver: async (data: RefuelingsFormValues) => {
      const formErrors = validateFields(props.validation, data);
      return {
        values: formErrors && Object.keys(formErrors).length ? {} : data, // if there's an error, return empty values
        errors: formErrors,
      };
    },
  });

  const create = async (createValues: RefuelingsFormValues) => {
    setIsLoaderActive(true);
    const newValues: RefuelingsFormValues = {
      ...createValues,
    };

    if (props.data?.source === CAN_SOURCE) {
      newValues.source = CAN_SOURCE;
      newValues.refuelingCanId = String((props.data as CanRefuelingDataToConvert).refuelingCanId);
    }

    if (createValues.vehicleId) {
      newValues.customerId = allVehicles[createValues.vehicleId].customerId;
    }

    if (!createValues.secondaryCurrencyId || !isSecondaryCurrency) {
      delete newValues.secondaryCurrencyId;
      delete newValues.secondaryVat;
      delete newValues.secondaryUnitPriceIncVat;
      delete newValues.secondaryUnitPrice;
      delete newValues.secondaryPrice;
      delete newValues.secondaryVatPrice;
      delete newValues.secondaryPriceIncVat;
    }
    if (props.onCreateCallback) {
      props.onCreateCallback(newValues);
    } else {
      console.warn('RefuelingsForm: No onCreateCallback provided');
    }
  };

  // update
  const update = async (updateValues: RefuelingsFormValues) => {
    if (props.data?.customerId) {
      const newValues = { ...updateValues, customerId: props.data.customerId };
      setIsLoaderActive(true);
      if (id) {
        delete newValues.vehicleId;
      }
      if (!updateValues.secondaryCurrencyId || !isSecondaryCurrency) {
        delete newValues.secondaryCurrencyId;
        delete newValues.secondaryVat;
        delete newValues.secondaryUnitPriceIncVat;
        delete newValues.secondaryUnitPrice;
        delete newValues.secondaryPrice;
        delete newValues.secondaryVatPrice;
        delete newValues.secondaryPriceIncVat;
      }
      if (props.onEditCallback) {
        props.onEditCallback(newValues);
      } else {
        console.warn('RefuelingsForm: No onEditCallback provided');
      }
    }
  };

  const onSubmit = (values: RefuelingsFormValues) => {
    if (props.data && props.data.id) {
      trackEvent(['refuelings form', 'submit', 'Edit refueling']);
      update({
        ...values,
        gasStationNetworkId: values.gasStationNetworkId === '' ? null : values.gasStationNetworkId,
      });
    } else {
      trackEvent(['refuelings form', 'submit', 'Add refueling']);
      create({
        ...values,
        gasStationNetworkId: values.gasStationNetworkId === '' ? null : values.gasStationNetworkId,
      });
      // create(values);
    }
  };

  const getLockedRideBoolean = (locked: string) => {
    return (
      locked === 'locked' ||
      (locked === 'restricted' &&
        props.data &&
        !PermissionsService.hasAccess(
          PERMISSION_PERIOD_LOCK_ADMIN,
          ['update'],
          props.data.customerId
        ))
    );
  };

  React.useEffect(() => {
    if (!watch('secondaryCurrencyId')) {
      clearErrors('secondaryVat');
      clearErrors('secondaryUnitPriceIncVat');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('secondaryCurrencyId')]);

  const getVehicleValue = (): string => {
    const vehicle = allVehicles[Number(props.data?.vehicleId)];
    if (vehicle) {
      return `${vehicle.licenceNumber} - ${vehicle.name}`;
    }
    return '';
  };

  const getUploadField = () => {
    let value = watch('attachments');

    if (value && Object.keys(value).length > 0 && value[0].fileName) {
      value.forEach((file) => {
        const thisFile = file;
        const { mimeType } = thisFile;
        const typeArr = mimeType.split('/');
        if (typeArr.length > 1 && typeArr[1] === 'pdf') {
          thisFile.cssType = '-pdf';
        } else if (typeArr[0] === 'application') {
          thisFile.cssType = '';
        } else {
          thisFile.cssType = `-${typeArr[0]}`;
        }
        return thisFile;
      });
      value = Object.values(value).map((file) => (
        <div className="label label-outline mb-xs py-xs" key={file.id}>
          <i className={`cmd-icons cmd-icon-file${file.cssType} fs-4 mr-xs`} />
          <span className="position-relative bottom-1">{file.fileName}</span>
        </div>
      ));
    }
    return String(value);
  };

  const handleSecondaryCurrency = () => {
    setIsSecondaryCurrency(!isSecondaryCurrency);
  };

  // const secondaryCurrencyButton: IFormSectionButton[] = [
  //   {
  //     title: f({ id: 'form.sections.refuelings.addOriginalPrice' }),
  //     buttonCallback: handleSecondaryCurrency,
  //   },
  // ];

  const getSubtitle = () => {
    const vehicle = allVehicles[Number(props.data?.vehicleId)];
    if (vehicle) {
      const date = watch('accountingTs')
        ? dayjs(watch('accountingTs')).format('D.M.YYYY H:mm')
        : ' - ';
      return `${vehicle.licenceNumber} (${date})`;
    }
    return '';
  };

  let buttons: CmdModalFooterButton[] = [
    {
      id: 'modalClose',
      type: 'button',
      title: f({ id: 'form.close' }),
      className: 'e-button',
      closeCallback: props.onCancelCallback,
      tooltip: f({ id: 'alarms.modal.timeFrame.button.close.tooltip' }),
    },
    {
      id: 'submitAddForm',
      type: 'submit',
      title: f({ id: 'form.save' }),
      className: 'e-button e-button--gray',
      submitCallback: handleSubmit(onSubmit),
      tooltip: f({ id: 'alarms.modal.recipient.save' }),
    },
  ];
  let isAllowed = true;

  // modal editing title and buttons
  if (id) {
    buttons = [
      {
        id: 'modalClose',
        type: 'button',
        title: f({ id: 'form.close' }),
        className: 'e-button',
        closeCallback: props.onCancelCallback,
        tooltip: f({ id: 'alarms.modal.timeFrame.button.close.tooltip' }),
      },
    ];
    isAllowed =
      PermissionsService.hasAccess(TABLE_REFUELING, ['update'], props.data?.customerId) &&
      !getLockedRideBoolean(props.additionalData?.locked || '');
    if (isAllowed) {
      buttons.push({
        id: 'submitEditForm',
        type: 'submit',
        title: f({ id: 'form.save' }),
        className: 'e-button e-button--gray',
        submitCallback: handleSubmit(onSubmit),
      });
    }
  }

  const handleDatepicker = (date: Date) => {
    if (date && (props.data?.id || watch('vehicleId'))) {
      const values = getValues();
      const newValues = { ...values };
      if (props.data?.id) {
        delete newValues.vehicleId;
      }
      newValues.accountingTs = date;
    }
  };

  const handleRideIdOptions = React.useCallback(
    async (vehicleId: number) => {
      const vehicle = allVehicles[vehicleId];
      if (vehicle) {
        const customerId = vehicle.customerId;
        const responseRideIdOptions = await props.additionalData?.getRideIdOptions(
          customerId,
          vehicle.id,
          dayjs(watch('accountingTs')).toISOString()
        );
        setRideIdOptions(responseRideIdOptions || []);
        if (Array.isArray(responseRideIdOptions) && responseRideIdOptions.length > 0) {
          setValue(
            'rideId',
            props.data?.rideId
              ? String(props.data.rideId)
              : responseRideIdOptions[responseRideIdOptions.length - 1].value
          );
          trigger('rideId');
        }
      }
    },
    [allVehicles, props.additionalData, props.data?.rideId, setValue, trigger, watch]
  );

  const handleCurrencyIdOptions = React.useCallback(
    async (vehicleId: number) => {
      const vehicle = allVehicles[vehicleId];
      if (vehicle) {
        const customerId = vehicle.customerId;
        const responseCurrencyIdOptions =
          await props.additionalData?.getCurrencyIdOption(customerId);
        if (responseCurrencyIdOptions) {
          const currency: IOption = {
            value: String(responseCurrencyIdOptions.currency.id),
            item: responseCurrencyIdOptions.currency.code,
          };
          setValue('currencyId', String(currency.value));
          setCurrencyIdOption({ [currency.value]: currency.item });
        }
      }
    },
    [allVehicles, props.additionalData, setValue]
  );

  const handleFeulTypeIdOptions = React.useCallback(
    async (vehicleId: number) => {
      const vehicle = allVehicles[vehicleId];
      if (vehicle) {
        const customerId = vehicle.customerId;
        const responseFuelTypeIdOptions =
          await props.additionalData?.getFuelTypeIdOptions(customerId);
        if (responseFuelTypeIdOptions) {
          setFuelTypeIdOptions(responseFuelTypeIdOptions);
        }
      }
    },
    [allVehicles, props.additionalData]
  );

  const handleGasStationNetworkIdOptions = React.useCallback(
    async (vehicleId: number) => {
      const vehicle = allVehicles[vehicleId];
      if (vehicle) {
        const customerId = vehicle.customerId;
        const responseGasStationNetworkIdOptions =
          await props.additionalData?.getGasStationNetworkIdOptions(customerId);
        if (responseGasStationNetworkIdOptions) {
          setGasStationNetworkIdOptions(responseGasStationNetworkIdOptions);
        }
      }
    },
    [allVehicles, props.additionalData]
  );

  const handleVatOptions = React.useCallback(
    async (vehicleId: number) => {
      const vehicle = allVehicles[vehicleId];
      if (vehicle) {
        const customerId = vehicle.customerId;
        const responseVatOptions = await props.additionalData?.getVatOption(customerId);
        if (responseVatOptions) {
          setValue('vat', String(responseVatOptions.defaultVat));
        }
      }
    },
    [allVehicles, props.additionalData, setValue]
  );

  const handleSecondaryCurrencyIdOptions = React.useCallback(
    async (vehicleId: number) => {
      const vehicle = allVehicles[vehicleId];
      if (vehicle) {
        const customerId = vehicle.customerId;
        const responseSecondaryCurrencyIdOptions =
          await props.additionalData?.getSecondaryCurrencyIdOptions(customerId);
        if (responseSecondaryCurrencyIdOptions) {
          setSecondaryCurrencyIdOptions(responseSecondaryCurrencyIdOptions);
        }
      }
    },
    [allVehicles, props.additionalData]
  );

  const getOptionsForVehicle = React.useCallback(
    async (vehicleId: number) => {
      handleRideIdOptions(vehicleId);
      handleCurrencyIdOptions(vehicleId);
      handleFeulTypeIdOptions(vehicleId);
      handleGasStationNetworkIdOptions(vehicleId);
      handleSecondaryCurrencyIdOptions(vehicleId);
      handleVatOptions(vehicleId);
      // const vehicle = allVehicles[vehicleId];
      // if (vehicle) {
      // const customerId = vehicle.customerId;
      // const responseRideIdOptions = await props.additionalData?.getRideIdOptions(customerId, vehicle.id, dayjs(watch('accountingTs')).toISOString());
      // setRideIdOptions(responseRideIdOptions || []);
      // if (Array.isArray(responseRideIdOptions) && responseRideIdOptions.length > 0) {
      //   setValue(
      //     'rideId',
      //     props.data?.rideId
      //       ? String(props.data.rideId)
      //       : responseRideIdOptions[responseRideIdOptions.length - 1].value
      //   );
      //   trigger('rideId');
      // }

      // const responseCurrencyIdOptions =
      //   await props.additionalData?.getCurrencyIdOption(customerId);
      // if (responseCurrencyIdOptions) {
      //   const currency: IOption = {
      //     value: String(responseCurrencyIdOptions.currency.id),
      //     item: responseCurrencyIdOptions.currency.code,
      //   };
      //   setValue('currencyId', String(currency.value));
      //   setCurrencyIdOption({ [currency.value]: currency.item });
      // }

      // const responseFuelTypeIdOptions =
      //   await props.additionalData?.getFuelTypeIdOptions(customerId);
      // if (responseFuelTypeIdOptions) {
      //   setFuelTypeIdOptions(responseFuelTypeIdOptions);
      // }

      // const responseGasStationNetworkIdOptions =
      //   await props.additionalData?.getGasStationNetworkIdOptions(customerId);
      // if (responseGasStationNetworkIdOptions) {
      //   setGasStationNetworkIdOptions(responseGasStationNetworkIdOptions);
      // }

      // const responseSecondaryCurrencyIdOptions =
      //   await props.additionalData?.getSecondaryCurrencyIdOptions(customerId);
      // if (responseSecondaryCurrencyIdOptions) {
      //   setSecondaryCurrencyIdOptions(responseSecondaryCurrencyIdOptions);
      // }

      // const responseVatOptions = await props.additionalData?.getVatOption(customerId);
      // if (responseVatOptions) {
      //   setValue('vat', String(responseVatOptions.defaultVat));
      // }
      // }
    },
    [
      handleCurrencyIdOptions,
      handleFeulTypeIdOptions,
      handleGasStationNetworkIdOptions,
      handleRideIdOptions,
      handleSecondaryCurrencyIdOptions,
      handleVatOptions,
    ]
  );

  const handleVehicle = React.useCallback(
    async (vehicleId: string | number | undefined | null) => {
      if (vehicleId) {
        setValue('vehicleId', String(vehicleId));
        trigger('vehicleId');
        getOptionsForVehicle(Number(vehicleId));
      } else {
        setValue('rideId', '');
        setRideIdOptions([]);
      }
    },
    [getOptionsForVehicle, setValue, trigger]
  );

  const isValidVatRange = (value: string) => {
    const regex = /^(100(\.00?)?|(\d{0,2})(\.\d{1,2})?)$/;
    return regex.test(value);
  };

  const isValidPriceRange = (value: string) => {
    const regex = /^\d{0,6}(\.\d{1,3})?$/;
    return regex.test(value);
  };

  const handleOnChangeSecondaryCurrency = () => {
    trackEvent(['refuelings form', 'change', 'Secondary currency change']);
    if (watch('secondaryCurrencyId')) {
      setIsSecondaryCurrency(true);
      const values = getValues();
      const newValues = { ...values };
      if (props.data?.id) {
        delete newValues.vehicleId;
      }
    }
  };

  const calculateAnotherValues = (
    unitPriceIncVatValue: number,
    vat: string | number,
    amountValue: number
  ) => {
    if (unitPriceIncVatValue && vat) {
      const vatValue =
        Number(typeof vat === 'string' && vat.length > 0 ? vat.replace(',', '.') : vat) / 100;
      const unitPriceValue =
        Math.round(Number(1000 * unitPriceIncVatValue) / (vatValue + 1)) / 1000;
      setValue(
        'vat',
        typeof vat === 'string' && vat.length > 0 ? String(vat.replace(',', '.')) : String(vat)
      );
      setValue('unitPrice', String(unitPriceValue.toFixed(3)));
      if (amountValue) {
        setValue('amount', String(amountValue));
      }
      setValue('price', String(Math.round(unitPriceValue * amountValue * 1000) / 1000));
      setValue('vatPrice', String(Math.round(unitPriceValue * amountValue * vatValue * 100) / 100));
      setValue('priceIncVat', String(Math.round(unitPriceIncVatValue * amountValue * 100) / 100));
    }
  };

  const handleUnitPriceIncVatChange = (value: number | string) => {
    calculateAnotherValues(Number(value), watch('vat'), Number(watch('amount')));
  };

  const handleVatChange = (value: string | number) => {
    calculateAnotherValues(Number(watch('unitPriceIncVat')), value, Number(watch('amount')));
  };

  const calculateSecondaryValues = (
    secondaryUnitPriceIncVatValue: number,
    vat: string | number,
    amountValue: number
  ) => {
    if (secondaryUnitPriceIncVatValue && vat) {
      const secondaryVatValue =
        Number(typeof vat === 'string' && vat.length > 0 ? vat.replace(',', '.') : vat) / 100;
      const secondaryUnitPriceValue =
        Math.round(Number(1000 * secondaryUnitPriceIncVatValue) / (secondaryVatValue + 1)) / 1000;
      setValue(
        'secondaryVat',
        typeof vat === 'string' && vat.length > 0 ? String(vat.replace(',', '.')) : String(vat)
      );
      setValue('secondaryUnitPrice', String(secondaryUnitPriceValue.toFixed(3)));
      setValue(
        'secondaryPrice',
        String(Math.round(secondaryUnitPriceValue * amountValue * 100) / 100)
      );
      setValue(
        'secondaryVatPrice',
        String(Math.round(secondaryUnitPriceValue * amountValue * secondaryVatValue * 100) / 100)
      );
      setValue(
        'secondaryPriceIncVat',
        String(Math.round(secondaryUnitPriceIncVatValue * amountValue * 100) / 100)
      );
      if (amountValue) {
        setValue('amount', String(amountValue));
      }
    }
  };

  const handleAmountChange = (value: number | string) => {
    calculateAnotherValues(Number(watch('unitPriceIncVat')), watch('vat'), Number(value));
    if (isSecondaryCurrency) {
      calculateSecondaryValues(
        Number(watch('secondaryUnitPriceIncVat')),
        watch('secondaryVat'),
        Number(value)
      );
    }
  };

  const handleSecondaryUnitPriceIncVatChange = (value: number | string) => {
    if (isSecondaryCurrency) {
      calculateSecondaryValues(Number(value), watch('secondaryVat'), Number(watch('amount')));
    }
  };

  const handleSecondaryVatChange = (value: string | number) => {
    if (isSecondaryCurrency) {
      calculateSecondaryValues(
        Number(watch('secondaryUnitPriceIncVat')),
        value,
        Number(watch('amount'))
      );
    }
  };

  React.useEffect(() => {
    if (selectedVehicles.length === 0 && !watch('vehicleId')) {
      setValue('vehicleId', '');
      trigger('vehicleId');
      setValue('rideId', '');
      trigger('rideId');
    }
    if (selectedVehicles.length === 1 && !watch('vehicleId')) {
      handleVehicle(selectedVehicles[0]);
    }
  }, [getOptionsForVehicle, getValues, handleVehicle, selectedVehicles, setValue, trigger, watch]);

  React.useEffect(() => {
    if (props.data?.id && watch('vehicleId')) {
      handleVehicle(watch('vehicleId'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleVehicle, props.data?.id]);

  React.useEffect(() => {
    if (props.data?.vehicleId && watch('vehicleId') && rideIdOptions.length === 0) {
      handleVehicle(watch('vehicleId'));
    }
  }, [handleVehicle, props.data?.vehicleId, watch, rideIdOptions]);

  React.useEffect(() => {
    if (watch('vehicleId') && watch('accountingTs')) {
      handleRideIdOptions(Number(watch('vehicleId')));
    }
  }, [watch('accountingTs')]);

  const getCurrencyCode = (): string => {
    return currencyIdOption[Number(watch('currencyId'))] || '';
  };

  const getSecondaryCurrencyCode = (): string => {
    const currencyString = secondaryCurrencyIdOptions.find(
      (currency: IOption) => Number(currency.value) === Number(watch('secondaryCurrencyId'))
    );
    return currencyString?.item || '';
  };

  const handleEditPrice = () => {
    setShowEditPriceForm(true);
  };

  const handleSaveChangedPrice = () => {
    setShowEditPriceForm(false);
  };

  const handleEditOriginalCurrency = () => {
    setShowEditCurrencyForm(true);
  };

  const handleSaveOriginalCurrency = () => {
    setShowEditCurrencyForm(false);
  };

  const priceButtons: CmdBoxButton[] = [];

  if (!showEditPriceForm) {
    priceButtons.push({
      title: f({ id: 'form.sections.refuelings.priceInfo.button.editData' }),
      buttonCallback: () => {
        handleEditPrice();
      },
      type: 'button',
      disabled: !getValues().vehicleId,
    });
  }

  if (showEditPriceForm) {
    priceButtons.push({
      title: f({ id: 'form.sections.refuelings.priceInfo.button.saveChangedPrice' }),
      buttonCallback: () => {
        handleSaveChangedPrice();
      },
      type: 'button',
      disabled: !getValues().vehicleId,
    });
  }

  if (!isSecondaryCurrency) {
    priceButtons.push({
      title: f({ id: 'form.sections.refuelings.priceInfo.button.addCurrency' }),
      buttonCallback: () => {
        handleSecondaryCurrency();
      },
      type: 'button',
      disabled: !getValues().vehicleId && !isSecondaryCurrency,
    });
  }

  const secondaryCurrencyButtons: CmdBoxButton[] = [];

  if (isSecondaryCurrency) {
    if (!showEditCurrencyForm) {
      secondaryCurrencyButtons.push({
        title: f({ id: 'form.sections.refuelings.priceInfo.button.editData' }),
        buttonCallback: () => {
          handleEditOriginalCurrency();
        },
        type: 'button',
        // disabled: !getValues().vehicleId,
      });
    }
    if (showEditCurrencyForm) {
      secondaryCurrencyButtons.push({
        title: f({ id: 'form.sections.refuelings.priceInfo.button.saveChangedPrice' }),
        buttonCallback: () => {
          handleSaveOriginalCurrency();
        },
        type: 'button',
        // disabled: !getValues().vehicleId,
      });
    }

    secondaryCurrencyButtons.push({
      title: f({ id: 'table.filter.remove' }),
      buttonCallback: () => {
        handleSecondaryCurrency();
      },
      type: 'button',
    });
  }

  const getPriceIncVatError = () => {
    if (!showEditPriceForm && errors.priceIncVat?.message && watch('priceIncVat')) {
      return f({ id: 'error.refuelings.priceIncVat' }, { count: 999999.99 });
    }
    return undefined;
  };

  return (
    <CmdSubpage
      id="refuelings-form"
      maintitle={f({ id: 'menu.refuelings' })}
      title={f({ id: 'menu.refuelingsOverview' })}
      subtitle={props.data?.id ? getSubtitle() : f({ id: 'table.add' })}
      buttons={buttons}
      loader={isLoaderActive}
      isNavigatorEnabled={true}
      onlineHelp={<OnlineHelp topic={props.data?.id ? 'edit-refueling' : 'add-refueling'} />}
    >
      {isAllowed ? (
        <div>
          <div className="row">
            <div className="col-xxl-6">
              <CmdBox
                id="refueling-basic-information"
                title={f({ id: 'form.sections.refuelings.basicInfo' })}
              >
                <Controller
                  name="accountingTs"
                  control={control}
                  rules={{
                    required: errors.accountingTs ? errors.accountingTs.message : undefined,
                  }}
                  render={({ field }) => (
                    <Calendar
                      {...field}
                      id="date"
                      name="date"
                      label={f({ id: 'form.inputs.refuelings.timestamp' })}
                      required={true}
                      enableTime={true}
                      error={errors.accountingTs?.message}
                      touched={true}
                      onChangeCallback={(value: Date) => {
                        setValue('accountingTs', value);
                        trigger('accountingTs');
                        handleDatepicker(value);
                        trackEvent(['refuelings form', 'change', 'Date change']);
                      }}
                    />
                  )}
                />
                {props.data && props.data.id ? (
                  <CmdLabel title={f({ id: 'form.inputs.vehicle' })} value={getVehicleValue()} />
                ) : (
                  <Controller
                    name="vehicleId"
                    control={control}
                    rules={{ required: errors.vehicleId ? errors.vehicleId.message : undefined }}
                    render={({ field }) => (
                      <CmdSimpleSelect
                        {...field}
                        name="vehicleId"
                        id="vehicleId"
                        label={f({ id: 'form.inputs.vehicle' })}
                        introOption={f({ id: 'form.chooseVehicle' })}
                        options={vehicleIdOptions}
                        required={true}
                        onChangeCallback={(value: string) => {
                          // trigger('vehicleId');
                          handleVehicle(value);
                          trackEvent(['refuelings form', 'change', 'Vehicle change']);
                        }}
                        error={errors.vehicleId?.message}
                        touched={true}
                      />
                    )}
                  />
                )}
                <Controller
                  name="rideId"
                  control={control}
                  rules={{ required: errors.rideId ? errors.rideId.message : undefined }}
                  render={({ field }) => (
                    <CmdSimpleSelect
                      {...field}
                      name="rideId"
                      id="rideId"
                      label={f({ id: 'form.inputs.refuelings.assignedRide' })}
                      introOption={f({
                        id: watch('vehicleId')
                          ? 'form.chooseRide'
                          : 'form.placeholders.selectVehicleFirst',
                      })}
                      options={convertDateInRides(rideIdOptions)}
                      required={true}
                      onChangeCallback={(value: string) => {
                        setValue('rideId', value);
                        trackEvent(['refuelings form', 'change', 'Ride change']);
                      }}
                      error={errors.rideId?.message}
                      touched={true}
                    />
                  )}
                />
                <Controller
                  name="gasStationNetworkId"
                  control={control}
                  rules={{
                    required: errors.gasStationNetworkId
                      ? errors.gasStationNetworkId.message
                      : undefined,
                  }}
                  render={({ field }) => (
                    <CmdSimpleSelect
                      {...field}
                      name="gasStationNetworkId"
                      id="gasStationNetworkId"
                      label={f({ id: 'form.inputs.refuelings.gasStation' })}
                      introOption={f({ id: 'form.chooseGasStationNetwork' })}
                      options={gasStationNetworkIdOptions}
                      onChangeCallback={(value: string) => {
                        setValue('gasStationNetworkId', value);
                        trigger('gasStationNetworkId');
                        trackEvent(['refuelings form', 'change', 'Gas station change']);
                      }}
                      error={errors.gasStationNetworkId?.message}
                      touched={true}
                    />
                  )}
                />
                <Controller
                  name="fuelTypeId"
                  control={control}
                  rules={{
                    required: errors.fuelTypeId ? errors.fuelTypeId.message : undefined,
                  }}
                  render={({ field }) => (
                    <CmdSimpleSelect
                      {...field}
                      name="fuelTypeId"
                      id="fuelTypeId"
                      label={f({ id: 'form.inputs.refuelings.fuelType' })}
                      introOption={f({ id: 'form.chooseFuelType' })}
                      options={fuelTypeIdOptions}
                      required={true}
                      onChangeCallback={(value: string) => {
                        setValue('fuelTypeId', value);
                        trigger('fuelTypeId');
                        trackEvent(['refuelings form', 'change', 'Fuel type change']);
                      }}
                      error={errors.fuelTypeId?.message}
                      touched={true}
                    />
                  )}
                />
                <Controller
                  name="amount"
                  control={control}
                  rules={{
                    required: errors.amount ? errors.amount.message : undefined,
                  }}
                  render={({ field }) => (
                    <CmdInput
                      {...field}
                      name="amount"
                      id="amount"
                      label={f({ id: 'form.inputs.refuelings.fuelAmount' })}
                      required={true}
                      placeholder={f({ id: 'form.enterFuelAmount' })}
                      onChangeCallback={(value: string) => {
                        setValue('amount', value);
                        trigger('amount');
                        handleAmountChange(value);
                      }}
                      type="number"
                      error={errors.amount?.message}
                      touched={true}
                    />
                  )}
                />
              </CmdBox>
              <CmdBox
                id="refuelings-additionalInfo"
                title={f({ id: 'form.sections.refuelings.additionalInfo' })}
              >
                <Controller
                  name="billId"
                  control={control}
                  rules={{
                    required: errors.billId ? errors.billId.message : undefined,
                  }}
                  render={({ field }) => (
                    <CmdInput
                      {...field}
                      name="billId"
                      id="billId"
                      label={f({ id: 'form.inputs.refuelings.billId' })}
                      type="text"
                      onChangeCallback={(value: string) => {
                        setValue('billId', value);
                        trigger('billId');
                      }}
                      error={errors.billId?.message}
                      touched
                    />
                  )}
                />
                <Controller
                  name="note"
                  control={control}
                  rules={{
                    required: errors.note ? errors.note.message : undefined,
                  }}
                  render={({ field }) => (
                    <CmdTextarea
                      {...field}
                      name="note"
                      id="note"
                      label={f({ id: 'form.inputs.note' })}
                      rows={5}
                      error={errors.note?.message}
                      setValue={(value: string) => {
                        setValue('note', value);
                        trigger('note');
                      }}
                      touched
                    />
                  )}
                />
                {watch('vehicleId') ? (
                  <Controller
                    name="attachments"
                    control={control}
                    render={({ field }) => (
                      <CmdUploadHookForm
                        {...field}
                        id="attachments"
                        label={f({ id: 'form.inputs.attachments' })}
                        number={3}
                        maxFileSize={3}
                        acceptedFileTypes={
                          props.validation &&
                          props.validation.attachments &&
                          props.validation.attachments[0] &&
                          props.validation.attachments[0].value
                        }
                        dnd={true}
                        url={`${configJs.Backend.url}/api/v1/file/upload${`?customerId=${
                          props.data?.id
                            ? props.data?.customerId
                            : allVehicles[watch('vehicleId')]?.customerId
                        }`}`}
                        control={control}
                        onChangeCallback={(value) => {
                          setValue('attachments', value);
                          trigger('attachments');
                          trackEvent(['refuelings form', 'change', 'Attachments change']);
                        }}
                      />
                    )}
                  />
                ) : (
                  <CmdLabel
                    title={f({ id: 'form.inputs.attachments' })}
                    value={f({ id: 'form.placeholders.corrections.selectVehicleFirst' })}
                  />
                )}
              </CmdBox>
            </div>

            <div className="col-xxl-6">
              {props.data?.mileage && props.data?.refuelingAfterDistanceKm && (
                <CmdBox
                  id="refuelings-additional-info"
                  title={f({ id: 'form.sections.refuelings.technicalInfo' })}
                >
                  <CmdLabel
                    title={f({ id: 'form.inputs.mileage' })}
                    value={String(props.data?.mileage)}
                    isWithSubpage={true}
                  />
                  <CmdLabel
                    title={f({ id: 'form.inputs.refuelings.refuelingAfterDistanceKm' })}
                    value={String(props.data.refuelingAfterDistanceKm)}
                    isWithSubpage={true}
                  />
                </CmdBox>
              )}
              <CmdBox
                id="refueling-price-info"
                title={f({ id: 'form.sections.refuelings.priceInfo' })}
                headerButtons2={priceButtons}
              >
                <CmdInput
                  id="currency"
                  label={f({ id: 'form.inputs.currency' })}
                  name="currency"
                  placeholder={f({ id: 'form.refueling.currency.placeholder' })}
                  value={getCurrencyCode()}
                  readonly={true}
                  disabled={watch('currencyId') === ''}
                  type="text"
                />
                <Controller
                  name="vat"
                  control={control}
                  rules={{
                    required: errors.vat ? errors.vat.message : undefined,
                  }}
                  render={({ field }) => (
                    <CmdInput
                      {...field}
                      name="vat"
                      id="vat"
                      label={f({ id: 'form.inputs.financial.vat' })}
                      placeholder={
                        !touchedFields.vat && watch('vat') === ''
                          ? f({
                              id: 'form.enterVat',
                            })
                          : ''
                      }
                      onChangeCallback={(value: string) => {
                        if (isValidVatRange(value)) {
                          setValue('vat', value, { shouldTouch: true });
                          trigger('vat');
                          handleVatChange(value);
                        }
                      }}
                      disabled={!touchedFields.vat && watch('vat') === ''}
                      type="number"
                      error={errors.vat?.message}
                      required={true}
                      touched={true}
                    />
                  )}
                />
                <Controller
                  name="unitPriceIncVat"
                  control={control}
                  rules={{
                    required: errors.unitPriceIncVat ? errors.unitPriceIncVat.message : undefined,
                  }}
                  render={({ field }) => (
                    <CmdInput
                      {...field}
                      name="unitPriceIncVat"
                      id="unitPriceIncVat"
                      label={f({ id: 'form.inputs.financial.unitPriceIncVat' })}
                      placeholder={f({ id: 'form.enterUnitPriceIncVat' })}
                      onChangeCallback={(value: string) => {
                        if (isValidPriceRange(value)) {
                          setValue('unitPriceIncVat', value);
                          trigger('unitPriceIncVat');
                          handleUnitPriceIncVatChange(value);
                        }
                      }}
                      type="number"
                      error={errors.unitPriceIncVat?.message || getPriceIncVatError()}
                      required={true}
                      currency={getCurrencyCode()}
                      touched={true}
                      min={0.0}
                    />
                  )}
                />
                <div
                  className="readonly-price"
                  style={!showEditPriceForm ? { display: 'block' } : { display: 'none' }}
                >
                  <CmdLabel
                    title={f({ id: 'form.inputs.financial.unitPrice' })}
                    value={getValues().unitPrice || '0.0'}
                  />
                  <CmdLabel
                    title={f({ id: 'form.inputs.financial.price' })}
                    value={getValues().price || '0.0'}
                  />
                  <CmdLabel
                    title={f({ id: 'form.inputs.financial.vatPrice' })}
                    value={getValues().vatPrice || '0.0'}
                  />
                  <hr />
                  <CmdLabel
                    title={f({ id: 'form.inputs.financial.priceIncVat' })}
                    value={getValues().priceIncVat || '0.0'}
                  />
                </div>
                <div
                  className="edit-price"
                  style={showEditPriceForm ? { display: 'block' } : { display: 'none' }}
                >
                  <Controller
                    name="unitPrice"
                    control={control}
                    rules={{
                      required: errors.unitPrice ? errors.unitPrice.message : undefined,
                    }}
                    render={({ field }) => (
                      <CmdInput
                        {...field}
                        name="unitPrice"
                        id="unitPrice"
                        label={f({ id: 'form.inputs.financial.unitPrice' })}
                        type="number"
                        error={errors.unitPrice?.message}
                        currency={getCurrencyCode()}
                        onChangeCallback={(value: string) => {
                          if (isValidPriceRange(value)) {
                            setValue('unitPrice', value);
                            trigger('unitPrice');
                          }
                        }}
                        touched
                      />
                    )}
                  />
                  <Controller
                    name="price"
                    control={control}
                    rules={{
                      required: errors.price ? errors.price.message : undefined,
                    }}
                    render={({ field }) => (
                      <CmdInput
                        {...field}
                        name="price"
                        id="price"
                        label={f({ id: 'form.inputs.financial.price' })}
                        type="number"
                        error={errors.priceIncVat?.message}
                        currency={getCurrencyCode()}
                        onChangeCallback={(value: string) => {
                          if (isValidPriceRange(value)) {
                            setValue('price', value);
                            trigger('price');
                          }
                        }}
                        touched
                      />
                    )}
                  />
                  <Controller
                    name="vatPrice"
                    control={control}
                    rules={{
                      required: errors.vatPrice ? errors.vatPrice.message : undefined,
                    }}
                    render={({ field }) => (
                      <CmdInput
                        {...field}
                        name="vatPrice"
                        id="vatPrice"
                        label={f({ id: 'form.inputs.financial.vatPrice' })}
                        type="number"
                        error={errors.vatPrice?.message}
                        currency={getCurrencyCode()}
                        onChangeCallback={(value: string) => {
                          if (isValidPriceRange(value)) {
                            setValue('vatPrice', value);
                            trigger('vatPrice');
                          }
                        }}
                        touched
                      />
                    )}
                  />
                  <Controller
                    name="priceIncVat"
                    control={control}
                    rules={{
                      required: errors.priceIncVat ? errors.priceIncVat.message : undefined,
                    }}
                    render={({ field }) => (
                      <CmdInput
                        {...field}
                        name="priceIncVat"
                        id="priceIncVat"
                        label={f({ id: 'form.inputs.financial.priceIncVat' })}
                        type="number"
                        error={errors.priceIncVat?.message}
                        currency={getCurrencyCode()}
                        onChangeCallback={(value: string) => {
                          if (isValidPriceRange(value)) {
                            setValue('priceIncVat', value);
                            trigger('priceIncVat');
                          }
                        }}
                        touched
                      />
                    )}
                  />
                </div>
              </CmdBox>
              {isSecondaryCurrency && (
                <CmdBox
                  id="refueling-secondry-price-info"
                  title={f({ id: 'form.sections.refuelings.currentCurrencyInfo' })}
                  headerButtons2={secondaryCurrencyButtons}
                >
                  <Controller
                    name="secondaryCurrencyId"
                    control={control}
                    rules={{
                      required: errors.secondaryCurrencyId
                        ? errors.secondaryCurrencyId.message
                        : undefined,
                    }}
                    render={({ field }) => (
                      <CmdSimpleSelect
                        {...field}
                        name="secondaryCurrencyId"
                        id="secondaryCurrencyId"
                        label={f({ id: 'form.inputs.currency' })}
                        introOption={f({ id: 'form.choose' })}
                        options={secondaryCurrencyIdOptions}
                        onChangeCallback={(value: string) => {
                          setValue('secondaryCurrencyId', value);
                          // handleSecondaryValidation();
                          trigger('secondaryCurrencyId');
                          trackEvent(['refuelings form', 'change', 'Secondary currency change']);
                        }}
                        error={errors.secondaryCurrencyId?.message}
                      />
                    )}
                  />
                  <Controller
                    name="secondaryVat"
                    control={control}
                    rules={{
                      required: errors.secondaryVat ? errors.secondaryVat.message : undefined,
                    }}
                    render={({ field }) => (
                      <CmdInput
                        {...field}
                        name="secondaryVat"
                        id="secondaryVat"
                        label={f({ id: 'form.inputs.financial.vat' })}
                        type="number"
                        required={!!watch('secondaryCurrencyId')}
                        disabled={!watch('secondaryCurrencyId')}
                        onChangeCallback={(value: string) => {
                          if (isValidVatRange(value)) {
                            setValue('secondaryVat', value);
                            trigger('secondaryVat');
                            handleSecondaryVatChange(value);
                          }
                        }}
                        error={errors.secondaryVat?.message}
                        touched
                        onBlurCallBack={handleOnChangeSecondaryCurrency}
                      />
                    )}
                  />
                  <Controller
                    name="secondaryUnitPriceIncVat"
                    control={control}
                    rules={{
                      required: errors.secondaryUnitPriceIncVat
                        ? errors.secondaryUnitPriceIncVat.message
                        : undefined,
                    }}
                    render={({ field }) => (
                      <CmdInput
                        {...field}
                        name="secondaryUnitPriceIncVat"
                        id="secondaryUnitPriceIncVat"
                        error={errors.secondaryUnitPriceIncVat?.message}
                        label={f({ id: 'form.inputs.financial.unitPriceIncVat' })}
                        type="number"
                        required={!!watch('secondaryCurrencyId')}
                        currency={getSecondaryCurrencyCode()}
                        disabled={!watch('secondaryCurrencyId')}
                        onChangeCallback={(value: string) => {
                          if (isValidPriceRange(value)) {
                            setValue('secondaryUnitPriceIncVat', value);
                            trigger('secondaryUnitPriceIncVat');
                            handleSecondaryUnitPriceIncVatChange(value);
                          }
                        }}
                        touched
                      />
                    )}
                  />
                  <div
                    className="readonly-original-price"
                    style={!showEditCurrencyForm ? { display: 'block' } : { display: 'none' }}
                  >
                    <CmdLabel
                      title={f({ id: 'form.inputs.financial.unitPrice' })}
                      value={getValues().secondaryUnitPrice || '0.0'}
                    />
                    <CmdLabel
                      title={f({ id: 'form.inputs.financial.price' })}
                      value={getValues().secondaryPrice || '0.0'}
                    />
                    <CmdLabel
                      title={f({ id: 'form.inputs.financial.vatPrice' })}
                      value={getValues().secondaryVatPrice || '0.0'}
                    />
                    <hr />
                    <CmdLabel
                      title={f({ id: 'form.inputs.financial.priceIncVat' })}
                      value={getValues().secondaryPriceIncVat || '0.0'}
                    />
                  </div>
                  <div
                    className="edit-oriinal-price"
                    style={showEditCurrencyForm ? { display: 'block' } : { display: 'none' }}
                  >
                    <Controller
                      name="secondaryUnitPrice"
                      control={control}
                      rules={{
                        required: errors.secondaryUnitPrice
                          ? errors.secondaryUnitPrice.message
                          : undefined,
                      }}
                      render={({ field }) => (
                        <CmdInput
                          {...field}
                          name="secondaryUnitPrice"
                          id="secondaryUnitPrice"
                          label={f({ id: 'form.inputs.financial.unitPrice' })}
                          type="number"
                          currency={getCurrencyCode()}
                          disabled={!watch('secondaryCurrencyId')}
                          error={errors.secondaryVat?.message}
                          onChangeCallback={(value: string) => {
                            if (isValidPriceRange(value)) {
                              setValue('secondaryUnitPrice', value);
                              trigger('secondaryUnitPrice');
                            }
                          }}
                        />
                      )}
                    />
                    <Controller
                      name="secondaryPrice"
                      control={control}
                      rules={{
                        required: errors.secondaryPrice ? errors.secondaryPrice.message : undefined,
                      }}
                      render={({ field }) => (
                        <CmdInput
                          {...field}
                          name="secondaryPrice"
                          id="secondaryPrice"
                          label={f({ id: 'form.inputs.financial.price' })}
                          type="number"
                          currency={getCurrencyCode()}
                          disabled={!watch('secondaryCurrencyId')}
                          error={errors.secondaryPrice?.message}
                          onChangeCallback={(value: string) => {
                            if (isValidPriceRange(value)) {
                              setValue('secondaryPrice', value);
                              trigger('secondaryPrice');
                            }
                          }}
                        />
                      )}
                    />
                    <Controller
                      name="secondaryVatPrice"
                      control={control}
                      rules={{
                        required: errors.secondaryCurrencyId
                          ? errors.secondaryCurrencyId.message
                          : undefined,
                      }}
                      render={({ field }) => (
                        <CmdInput
                          {...field}
                          name="secondaryVatPrice"
                          id="secondaryVatPrice"
                          label={f({ id: 'form.inputs.financial.vatPrice' })}
                          type="number"
                          currency={getCurrencyCode()}
                          disabled={!watch('secondaryCurrencyId')}
                          error={errors.secondaryVatPrice?.message}
                          onChangeCallback={(value: string) => {
                            if (isValidPriceRange(value)) {
                              setValue('secondaryVatPrice', value);
                              trigger('secondaryVatPrice');
                            }
                          }}
                        />
                      )}
                    />
                    <Controller
                      name="secondaryPriceIncVat"
                      control={control}
                      rules={{
                        required: errors.secondaryPriceIncVat
                          ? errors.secondaryPriceIncVat.message
                          : undefined,
                      }}
                      render={({ field }) => (
                        <CmdInput
                          {...field}
                          name="secondaryPriceIncVat"
                          id="secondaryPriceIncVat"
                          label={f({ id: 'form.inputs.financial.priceIncVat' })}
                          type="number"
                          currency={getCurrencyCode()}
                          disabled={!watch('secondaryCurrencyId')}
                          error={errors.secondaryPriceIncVat?.message}
                          onChangeCallback={(value: string) => {
                            if (isValidPriceRange(value)) {
                              setValue('secondaryPriceIncVat', value);
                              trigger('secondaryPriceIncVat');
                            }
                          }}
                        />
                      )}
                    />
                  </div>
                </CmdBox>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="row">
            <div className="col-xxl-6">
              <CmdBox
                id="refueling-basic-information"
                title={f({ id: 'form.sections.refuelings.basicInfo' })}
              >
                <CmdLabel
                  title={f({ id: 'form.inputs.refuelings.timestamp' })}
                  value={dayjs(watch('accountingTs')).format('D.M.YYYY H:mm')}
                  isWithSubpage={true}
                />
                <CmdLabel
                  title={f({ id: 'form.inputs.vehicle' })}
                  value={String(getVehicleValue())}
                  isWithSubpage={true}
                />
                <CmdLabel
                  title={f({ id: 'form.inputs.refuelings.assignedRide' })}
                  value={getLabelValue(rideIdOptions, watch('rideId'))}
                  isWithSubpage={true}
                />
                <CmdLabel
                  title={f({ id: 'form.inputs.refuelings.gasStation' })}
                  value={getLabelValue(
                    gasStationNetworkIdOptions,
                    String(watch('gasStationNetworkId'))
                  )}
                  isWithSubpage={true}
                />
                <CmdLabel
                  title={f({ id: 'form.inputs.refuelings.fuelType' })}
                  value={getLabelValue(fuelTypeIdOptions, watch('fuelTypeId'))}
                  isWithSubpage={true}
                />
                <CmdLabel
                  title={f({ id: 'form.inputs.refuelings.fuelAmount' })}
                  value={watch('amount')}
                  isWithSubpage={true}
                />
              </CmdBox>
              <CmdBox
                id="refuelings-additionalInfo"
                title={f({ id: 'form.sections.refuelings.additionalInfo' })}
              >
                <CmdLabel
                  title={f({ id: 'form.inputs.refuelings.billId' })}
                  value={watch('billId')}
                  isWithSubpage={true}
                />
                <CmdLabel
                  title={f({ id: 'form.inputs.note' })}
                  value={watch('note')}
                  isWithSubpage={true}
                />
                <CmdLabel
                  title={f({ id: 'form.inputs.attachments' })}
                  value={getUploadField()}
                  isWithSubpage={true}
                />
              </CmdBox>
            </div>

            <div className="col-xxl-6">
              <CmdBox
                id="refueling-price-info"
                title={f({ id: 'form.sections.refuelings.priceInfo' })}
              >
                <CmdLabel
                  title={f({ id: 'form.inputs.currency' })}
                  value={getCurrencyCode()}
                  isWithSubpage={true}
                />
                <CmdLabel
                  title={f({ id: 'form.inputs.financial.vat' })}
                  value={watch('vat')}
                  isWithSubpage={true}
                />
                <CmdLabel
                  title={f({ id: 'form.inputs.financial.unitPriceIncVat' })}
                  value={watch('unitPriceIncVat')}
                  isWithSubpage={true}
                />
                <CmdLabel
                  title={f({ id: 'form.inputs.financial.unitPrice' })}
                  value={watch('unitPrice')}
                  isWithSubpage={true}
                />
                <CmdLabel
                  title={f({ id: 'form.inputs.financial.price' })}
                  value={watch('price')}
                  isWithSubpage={true}
                />
                <CmdLabel
                  title={f({ id: 'form.inputs.financial.vatPrice' })}
                  value={watch('vatPrice')}
                  isWithSubpage={true}
                />
                <CmdLabel
                  title={f({ id: 'form.inputs.financial.priceIncVat' })}
                  value={watch('priceIncVat')}
                  isWithSubpage={true}
                />
                {isSecondaryCurrency && (
                  <>
                    <hr
                      style={{
                        marginRight: '-16px',
                        marginLeft: '-16px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        borderTop: '1px solid #ddd',
                      }}
                    />
                    <CmdLabel
                      title={f({ id: 'form.inputs.currency' })}
                      value={getLabelValue(
                        secondaryCurrencyIdOptions,
                        watch('secondaryCurrencyId')
                      )}
                      isWithSubpage={true}
                    />
                    <CmdLabel
                      title={f({ id: 'form.inputs.financial.vat' })}
                      value={watch('secondaryVat')}
                      isWithSubpage={true}
                    />
                    <CmdLabel
                      title={f({ id: 'form.inputs.financial.unitPriceIncVat' })}
                      value={watch('secondaryUnitPriceIncVat')}
                      isWithSubpage={true}
                    />
                    <CmdLabel
                      title={f({ id: 'form.inputs.financial.unitPrice' })}
                      value={watch('secondaryUnitPrice')}
                      isWithSubpage={true}
                    />
                    <CmdLabel
                      value={watch('secondaryPrice')}
                      title={f({ id: 'form.inputs.financial.price' })}
                      isWithSubpage={true}
                    />
                    <CmdLabel
                      value={watch('secondaryVatPrice')}
                      title={f({ id: 'form.inputs.financial.vatPrice' })}
                      isWithSubpage={true}
                    />
                    <CmdLabel
                      value={watch('secondaryPrice')}
                      title={f({ id: 'form.inputs.financial.priceIncVat' })}
                      isWithSubpage={true}
                    />
                  </>
                )}
              </CmdBox>
              <CmdBox
                id="refuelings-additional-info"
                title={f({ id: 'form.sections.refuelings.technicalInfo' })}
              >
                <CmdLabel
                  title={f({ id: 'form.inputs.mileage' })}
                  value={watch('mileage')}
                  isWithSubpage={true}
                />
                <CmdLabel
                  title={f({ id: 'form.inputs.refuelings.refuelingAfterDistanceKm' })}
                  value={watch('refuelingAfterDistanceKm')}
                  isWithSubpage={true}
                />
              </CmdBox>
            </div>
          </div>
        </div>
      )}
    </CmdSubpage>
  );
}
