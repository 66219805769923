import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { useQueryClient } from '@tanstack/react-query';
import useLocation from 'wouter/use-location';
import ActionsMenu from './ActionsMenu';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import useKeyPress, { ESCAPE_KEY } from '../../../../hooks/useKeyPress';
import {
  CAN_REFUELINGS_DUPLICATE_KEY,
  CAN_REFUELINGS_STATUS_KEY,
  CAN_REFUELINGS_TABLE_KEY,
  CanRefuelingsRecord,
} from '../types';
import { duplicate, ignore, remove } from '../CanRefuelingsService';
import { ICmdTableFilter } from '../../../CmdTable/types';
import { URL_CAN_REFUELINGS } from '../../../../router/constants';
import { selectedVehiclesAtom } from '../../../../store/recoil/vehicles';
import { selectedCustomersAtom } from '../../../../store/recoil/customers';
import { filterForRequestAtomFamily, sortFilterAtomFamily } from '../../../CmdTable/CmdTableState';

export interface IActionsProps<T extends CanRefuelingsRecord> {
  data: T;
}

declare global {
  interface Window {
    resizeTimeout?: number;
  }
}

function Actions<T extends CanRefuelingsRecord>(props: IActionsProps<T>): JSX.Element {
  const { formatMessage: f } = useIntl();
  const queryClient = useQueryClient();
  const [_, navigate] = useLocation();
  const filterForRequest = useRecoilValue<ICmdTableFilter>(
    filterForRequestAtomFamily(CAN_REFUELINGS_TABLE_KEY)
  );
  const selectedCustomers = useRecoilValue(selectedCustomersAtom);
  const selectedVehicles = useRecoilValue(selectedVehiclesAtom);
  const sortFilter = useRecoilValue<{ [key: string]: string }>(
    sortFilterAtomFamily(CAN_REFUELINGS_TABLE_KEY)
  );
  const [isActionsMenuOpened, setIsActionsMenuOpened] = React.useState(false);
  const [menuPosition, setMenuPosition] = React.useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  });
  const [isMenuVisible, setIsMenuVisible] = React.useState(true);
  const menuRef: React.RefObject<HTMLDivElement> = React.createRef();
  const clickOutsideRef: React.RefObject<HTMLDivElement> = React.createRef();
  const lastPosition = React.useRef<{ top: number; left: number }>({ top: 0, left: 0 });

  useOutsideClick(
    clickOutsideRef,
    (e) => {
      setIsActionsMenuOpened(false);
    },
    [menuRef]
  );

  useKeyPress(ESCAPE_KEY, () => {
    setIsActionsMenuOpened(false);
  });

  const updateTable = React.useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [
        CAN_REFUELINGS_TABLE_KEY,
        filterForRequest,
        sortFilter,
        selectedCustomers,
        selectedVehicles,
      ],
    });
  }, [filterForRequest, queryClient, selectedCustomers, selectedVehicles, sortFilter]);

  const isElementVisible = React.useCallback((): boolean => {
    const rect = clickOutsideRef.current?.getBoundingClientRect();
    const header = document.getElementById('cmd-table-header');
    const table = document.getElementById('cmd-table');

    const headerBottom = header ? header.getBoundingClientRect().bottom : 0;
    // const tableTop = table ? table.getBoundingClientRect().top : 0;
    const tableBottom = table ? table.getBoundingClientRect().bottom : 0;

    return !!(
      rect &&
      rect.top >= headerBottom && // Ensure the top of the element is below the bottom of the header
      rect.bottom <= tableBottom && // Ensure the bottom of the element is above the bottom of the table
      rect.right > 0 &&
      rect.left < (window.innerWidth || document.documentElement.clientWidth)
    );
  }, [clickOutsideRef]);

  const updateMenuPosition = React.useCallback(() => {
    if (clickOutsideRef.current) {
      const rect = clickOutsideRef.current.getBoundingClientRect();
      const table = document.getElementById('cmd-table');
      const tableBottom = table ? table.getBoundingClientRect().bottom : 0;
      const menuHeight = 176;

      const newPosition = {
        top: rect.bottom + 9 + window.scrollY,
        left: rect.right - 129 + window.scrollX,
      };

      if (rect.bottom + menuHeight > tableBottom) {
        newPosition.top = rect.top - menuHeight + window.scrollY;
      }

      // Only update if position actually changes
      if (
        newPosition.top !== lastPosition.current.top ||
        newPosition.left !== lastPosition.current.left
      ) {
        setMenuPosition(newPosition);
        lastPosition.current = newPosition; // Update the last known position
      }
    }
  }, [clickOutsideRef]);

  const handleResizeOrScroll = React.useCallback(() => {
    clearTimeout(window.resizeTimeout);
    window.resizeTimeout = window.setTimeout(() => {
      if (isActionsMenuOpened) {
        if (isElementVisible()) {
          updateMenuPosition();
          setIsMenuVisible(true);
        } else {
          setIsMenuVisible(false);
          // setIsActionsMenuOpened(false);
        }
      }
    }, 100);
  }, [isActionsMenuOpened, updateMenuPosition, isElementVisible]);

  React.useEffect(() => {
    window.addEventListener('scroll', handleResizeOrScroll);
    window.addEventListener('resize', handleResizeOrScroll);

    // Initial position update
    if (isActionsMenuOpened) {
      updateMenuPosition();
      setIsMenuVisible(isElementVisible());
    }

    return () => {
      window.removeEventListener('scroll', handleResizeOrScroll);
      window.removeEventListener('resize', handleResizeOrScroll);
      clearTimeout(window.resizeTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleResizeOrScroll, isActionsMenuOpened, updateMenuPosition]);

  const handleActions = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const newState = !isActionsMenuOpened;
    setIsActionsMenuOpened(newState);
    if (newState) {
      const isVisible = isElementVisible();
      setIsMenuVisible(isVisible);
      if (isVisible) {
        updateMenuPosition();
      }
    }
  };

  const updateCellValue = (id: number, key: string, value: string | boolean) => {
    const updatedFilterForRequest = { ...filterForRequest };
    updatedFilterForRequest.vehicle = selectedVehicles;
    queryClient.setQueryData(
      [
        CAN_REFUELINGS_TABLE_KEY,
        updatedFilterForRequest,
        sortFilter,
        selectedCustomers,
        selectedVehicles,
      ],
      (oldData: any) => {
        if (!oldData) {
          return oldData;
        }
        const newData = {
          ...oldData,
          pages: oldData.pages.map((page: any) => ({
            ...page,
            records: page.records.map((record: CanRefuelingsRecord) =>
              record.id === id ? { ...record, [key]: value } : record
            ),
          })),
        };
        return newData;
      }
    );
  };

  const handleConvertToRefueling = () => {
    navigate(
      `${URL_CAN_REFUELINGS}/convert-to-refueling?vehicleId=${props.data.vehicleId}&customerId=${props.data.customer.id}&rideId=${props.data.rideId}&fuelTypeId=${props.data.fuelTypeId}&amount=${props.data.amount}&accountingTs=${props.data.accountingTs}&refuelingCanId=${props.data.id}`
    );
  };

  const handleIgnore = async () => {
    const response = await ignore(props.data.id, props.data.customer.id);
    if (response) {
      updateCellValue(props.data.id, CAN_REFUELINGS_STATUS_KEY, response.status);
    }
  };

  const handleDuplicate = async () => {
    const response = await duplicate(props.data.id, props.data.customer.id);
    if (response) {
      updateCellValue(props.data.id, CAN_REFUELINGS_DUPLICATE_KEY, response.duplicate);
    }
  };

  const handleRemove = async () => {
    const response = await remove(props.data.id, props.data.customer.id);
    if (response) {
      updateTable();
      setIsActionsMenuOpened(false);
    }
  };

  return (
    <div
      ref={clickOutsideRef}
      style={{
        zIndex: isActionsMenuOpened ? 10 : 4,
        margin: 'auto',
      }}
      key={props.data.id}
    >
      <div className="">
        <button
          className="text-gray-lighten"
          onClick={handleActions}
          style={{ padding: '15px', margin: '-15px' }}
          id={`rideActions-${props.data.id}`}
          data-cy="actions-menu-ride"
          title={f({ id: 'table.actionsBtn' })}
        >
          <i className="cmd-icons cmd-icon-more-horizontal fs-4" id={`ride-${props.data.id}`} />
          <span className="sr-only">{f({ id: 'table.actionsBtn' })}</span>
        </button>
        {isActionsMenuOpened && isMenuVisible && (
          <ActionsMenu
            data={props.data}
            position={menuPosition}
            ref={menuRef}
            ignoreCallback={handleIgnore}
            removeCallback={handleRemove}
            duplicateCallback={handleDuplicate}
            convertToRefuelingCallback={handleConvertToRefueling}
          />
        )}
      </div>
    </div>
  );
}

export default Actions;
