import * as React from 'react';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { useRecoilState } from 'recoil';
import { useIntl } from 'react-intl';
import MultiSelectFilter from '../Filter/MultiSelectFilter';
import RangeDurationFilter from '../Filter/RangeDurationFilter';
import RangeSelectFilter from '../Filter/RangeSelectFilter';
import SimpleSelectFilter from '../Filter/SimpleSelectFilter';
import useFilter from '../hooks/useFilter';
import TableDatepicker from '../../TableDatepicker';
import { ICmdTableFilter, IFilterChipsValues, ITLCFilter, ICmdTableData } from '../types';
import { IDatepickerSettings, setTableDatepickerAtom } from '../../../store/recoil/TableState';
import {
  BLE_TABLE_NAME,
  BLE_USAGE_TABLE_NAME,
  BlucoinsTableTranslationKeys,
  BlucoinsUsageTableTranslationKeys,
  TIME_KEY,
} from '../../Bluecoins/constants';
import { CUSTOMER_KEY } from '../../TripsOverviewNew/constants';
import TextFilter from '../Filter/TextFilter';
import { filterForRequestAtomFamily, tableFilterAtomFamily } from '../CmdTableState';

interface IFilterChipsProps {
  id: string;
  tableName: string;
}

function FilterChips(props: IFilterChipsProps): JSX.Element | null {
  dayjs.extend(weekOfYear);
  const { formatMessage: f } = useIntl();
  const { updateFilterItemsState } = useFilter(props.tableName);
  const [datepickerSettings, setDatepickerSettings] = useRecoilState<IDatepickerSettings | null>(
    setTableDatepickerAtom
  );
  const [minDatepickerTimestamp, setMinDatepickerTimestamp] = React.useState<Date | string>('');
  const [maxDatepickerTimestamp, setMaxDatepickerTimestamp] = React.useState<Date | string>('');
  const [tableActionsFilters, setTableActionsFilters] = useRecoilState(
    tableFilterAtomFamily({ [props.tableName]: props.id })
  );
  const [filterForRequest, setFilterForRequest] = useRecoilState<ICmdTableFilter>(
    filterForRequestAtomFamily(props.tableName)
  );

  const handleRemoveChips = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    const { id } = event.currentTarget;

    const data = { ...filterForRequest };

    delete data[props.id];

    setFilterForRequest(data);

    setTableActionsFilters(
      (tableActionFilter) =>
        tableActionFilter && {
          ...tableActionFilter,
          id: props.id,
          isFilterActive: false,
          isActionChipsOpen: false,
          isOpen: false,
          values: null,
        }
    );
  };

  const handleRemoveDatepickerChip = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const type = 'date_range_picker';
    setDatepickerSettings({
      ...datepickerSettings,
      [props.id]: { type, date: [], interval: null },
    });
    handleRemoveChips(event);
  };

  const handleOpenFilter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();

    if (tableActionsFilters && !tableActionsFilters.isActionChipsOpen) {
      if (tableActionsFilters) {
        updateFilterItemsState([
          {
            ...tableActionsFilters,
            id: props.id,
            isOpen: false,
            isActionChipsOpen: true,
          },
        ]);
      }
    }
  };

  const handleTranslation = (key: string) => {
    if (props.tableName === BLE_TABLE_NAME) {
      return f({ id: BlucoinsTableTranslationKeys[key] });
    }
    if (props.tableName === BLE_USAGE_TABLE_NAME) {
      return f({ id: BlucoinsUsageTableTranslationKeys[key] });
    }
    return f({ id: `table_list.columns.${key}` });
  };

  React.useEffect(() => {
    if (
      datepickerSettings &&
      datepickerSettings[props.id] &&
      datepickerSettings[props.id].date &&
      datepickerSettings[props.id].date.length === 2
    ) {
      if (
        dayjs(datepickerSettings[props.id].date[0]).format('DD.MM.YYYY HH:mm') !==
        dayjs(minDatepickerTimestamp).format('DD.MM.YYYY HH:mm')
      ) {
        setMinDatepickerTimestamp(datepickerSettings[props.id].date[0]);
      }
      if (
        dayjs(datepickerSettings[props.id].date[1]).format('DD.MM.YYYY HH:mm') !==
        dayjs(maxDatepickerTimestamp).format('DD.MM.YYYY HH:mm')
      ) {
        setMaxDatepickerTimestamp(datepickerSettings[props.id].date[1]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datepickerSettings]);

  React.useEffect(() => {
    if (
      minDatepickerTimestamp &&
      maxDatepickerTimestamp &&
      tableActionsFilters &&
      tableActionsFilters.id === props.id
    ) {
      // initTable();

      const selectedValues: IFilterChipsValues = {
        item: `${dayjs(minDatepickerTimestamp).format('DD.MM.YYYY HH:mm')} - ${dayjs(
          maxDatepickerTimestamp
        ).format('DD.MM.YYYY HH:mm')}`,
        value: '',
        title: handleTranslation(props.id),
        type: 'datetime',
      };

      const data = {};

      data[props.id] = {
        min: minDatepickerTimestamp,
        max: maxDatepickerTimestamp,
      };

      setFilterForRequest((oldState: ICmdTableFilter) => {
        const newState: ITLCFilter = {
          ...oldState,
          ...data,
        };
        return newState;
      });

      setTableActionsFilters(
        (tableActionFilter) =>
          tableActionFilter && {
            ...tableActionFilter,
            id: props.id,
            isOpen: false,
            isActionChipsOpen: false,
            isFilterActive: true,
            values: { ...selectedValues },
          }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minDatepickerTimestamp, maxDatepickerTimestamp]);

  const renderDapickerChip = (): JSX.Element | null => {
    return tableActionsFilters ? (
      <TableDatepicker type="chip" name={props.id} isActive={true}>
        <div
          id={props.id}
          className="label label-primary mr-1 mb-1 label--filter dropdown-toggle"
          onClick={handleOpenFilter}
          style={{ position: 'relative' }}
          data-cy={`tlc-filter-chips-${props.id}`}
        >
          {`${tableActionsFilters.values?.title}: ${tableActionsFilters.values?.item}`}

          <i
            id={props.id}
            className="cmd-icon cmd-icon-remove-sm"
            style={{ paddingLeft: '8px' }}
            onClick={handleRemoveDatepickerChip}
            data-cy="tlc-filter-chips-close"
          />
        </div>
      </TableDatepicker>
    ) : null;
  };

  const renderChip = (): JSX.Element | null => {
    return tableActionsFilters ? (
      <div style={{ position: 'relative' }}>
        {tableActionsFilters.isActionChipsOpen &&
          tableActionsFilters.values &&
          tableActionsFilters.values?.type === 'rangeSelect' &&
          props.id !== TIME_KEY && (
            <div
              id="range-select"
              style={{ position: 'absolute', left: '8px', bottom: '-16px', zIndex: 200 }}
              className="text-black"
            >
              <div className="open">
                <RangeSelectFilter
                  id={props.id}
                  tableName={props.tableName}
                  title={tableActionsFilters.values.title}
                  valueFrom={tableActionsFilters.values.min}
                  valueTo={tableActionsFilters.values.max}
                  defaultMinValue={
                    tableActionsFilters.data && tableActionsFilters.data.minValue
                      ? tableActionsFilters.data.minValue
                      : '0'
                  }
                  defaultMaxValue={
                    tableActionsFilters.data && tableActionsFilters.data.maxValue
                      ? tableActionsFilters.data.maxValue
                      : '9999999'
                  }
                  note={tableActionsFilters.values.unit}
                  suffix={tableActionsFilters.values.suffixInput}
                />
              </div>
            </div>
          )}

        {tableActionsFilters.isActionChipsOpen &&
          tableActionsFilters.values?.type === 'simpleSelect' &&
          tableActionsFilters.data && (
            <div style={{ position: 'absolute', left: '0px', bottom: '5px' }}>
              <div className="label open">
                <SimpleSelectFilter
                  id={props.id}
                  tableName={props.tableName}
                  filterValues={tableActionsFilters.data}
                  search={props.id === CUSTOMER_KEY}
                  title={tableActionsFilters.values.title}
                />
              </div>
            </div>
          )}
        {tableActionsFilters.isActionChipsOpen &&
          tableActionsFilters.values?.type === 'rangeSelect' &&
          props.id === TIME_KEY && (
            <div
              style={{ position: 'absolute', left: '0px', top: '33px', zIndex: 200 }}
              className="text-black"
            >
              <RangeDurationFilter id={props.id} tableName={props.tableName} />
            </div>
          )}
        {tableActionsFilters.isActionChipsOpen &&
          tableActionsFilters.values?.type === 'multiSelect' &&
          tableActionsFilters.data && (
            <div style={{ position: 'absolute', left: '0px', top: '30px', zIndex: 999999999 }}>
              <div className="open">
                <MultiSelectFilter
                  id={props.id}
                  tableName={props.tableName}
                  filterValues={tableActionsFilters.data}
                  title={tableActionsFilters.values.title}
                />
              </div>
            </div>
          )}
        {tableActionsFilters.isActionChipsOpen &&
          tableActionsFilters.values?.type === 'inputSearch' &&
          tableActionsFilters.data && (
            <div style={{ position: 'absolute', left: '0px', bottom: '6px' }}>
              <div className="open">
                <TextFilter
                  tableName={props.tableName}
                  id={props.id}
                  filterValues={tableActionsFilters.data}
                  title={tableActionsFilters.values.title}
                />
              </div>
            </div>
          )}

        <div
          id={props.id}
          className="label label-primary mr-1 mb-1 label--filter dropdown-toggle"
          onClick={handleOpenFilter}
          // style={{ position: 'relative' }}
          data-cy={`tlc-filter-chips-${props.id}`}
        >
          {`${tableActionsFilters.values?.title}: ${tableActionsFilters.values?.item}`}

          <i
            id={props.id}
            className="cmd-icon cmd-icon-remove-sm"
            style={{ paddingLeft: '8px' }}
            onClick={handleRemoveChips}
            data-cy="tlc-filter-chips-close"
          />
        </div>
      </div>
    ) : null;
  };

  const renderElement = () => {
    return tableActionsFilters &&
      tableActionsFilters.values?.type === 'datetime' &&
      tableActionsFilters.data
      ? renderDapickerChip()
      : renderChip();
  };

  return tableActionsFilters && tableActionsFilters.values ? renderElement() : null;
}

export default React.memo(FilterChips);
