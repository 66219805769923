import * as React from 'react';
import { CmdSearchFilter } from '@commander-services/gui-components';
import { useIntl } from 'react-intl';
import { useRecoilState, useSetRecoilState } from 'recoil';
import useOutsideClick from '../../../hooks/useOutsideClick';
import useKeyPress, { ESCAPE_KEY } from '../../../hooks/useKeyPress';
import { ICmdTableFilterTable, ICmdTableFilter, IFilterChipsValues } from '../types';
import { filterForRequestAtomFamily, tableFilterAtomFamily } from '../CmdTableState';

interface ITextFilterProps {
  id: string;
  filterValues: ICmdTableFilterTable;
  tableName: string;
  title?: string;
}

export default function TextFilter(props: ITextFilterProps): JSX.Element {
  const [tableActionsFilters, setTableActionsFilters] = useRecoilState(
    tableFilterAtomFamily({ [props.tableName]: props.id })
  );
  const setFilterForRequest = useSetRecoilState<ICmdTableFilter>(
    filterForRequestAtomFamily(props.tableName)
  );

  const { formatMessage: f } = useIntl();

  const clickOutsideRef: React.RefObject<HTMLDivElement> = React.createRef();

  useOutsideClick(clickOutsideRef, () => {
    setTableActionsFilters(
      (tableActionFilter) =>
        tableActionFilter && {
          ...tableActionFilter,
          id: props.id,
          isOpen: false,
          isActionChipsOpen: false,
        }
    );
  });

  useKeyPress(ESCAPE_KEY, () => {
    setTableActionsFilters(
      (tableActionFilter) =>
        tableActionFilter && {
          ...tableActionFilter,
          id: props.id,
          isOpen: false,
          isActionChipsOpen: false,
        }
    );
  });

  const handleSearch = (value: string) => {
    if (props.filterValues) {
      const selectedValues: IFilterChipsValues = {
        item: value,
        value,
        title: props.title ? props.title : '',
        type: 'inputSearch',
      };

      const data = {};

      data[props.id] = value;

      setFilterForRequest((prevFilter) => {
        if (!value) {
          const newFilter = { ...prevFilter };
          delete newFilter[props.id];
          return newFilter;
        }
        return {
          ...prevFilter,
          ...data,
        };
      });

      setTableActionsFilters((tableActionFilter) => {
        if (tableActionFilter) {
          let newValues: IFilterChipsValues | null = null;
          if (
            tableActionFilter &&
            tableActionFilter.values === null &&
            selectedValues.value !== ''
          ) {
            newValues = { ...selectedValues };
          }
          if (tableActionFilter && tableActionFilter.values !== null) {
            const newOldValues = tableActionFilter.values;
            if (selectedValues.value === '' && tableActionFilter.values[selectedValues.title]) {
              delete newOldValues[selectedValues.title];
              newValues = newOldValues;
            }
            if (selectedValues.value !== '') {
              newValues = { ...newOldValues, ...selectedValues };
            }
          }
          return {
            ...tableActionFilter,
            id: props.id,
            isOpen: value === '',
            isActionChipsOpen: false,
            isFilterActive: true,
            values: newValues,
          };
        }
        return tableActionFilter;
      });
    }
  };

  return (
    <div
      ref={clickOutsideRef}
      style={{ position: 'absolute', left: '-24px', bottom: '-15px', zIndex: 999 }}
      className="table-filter"
    >
      <div className="label open" style={{ textAlign: 'left', minWidth: '220px' }}>
        <div>
          <CmdSearchFilter
            id={props.id}
            value={
              tableActionsFilters && tableActionsFilters.values
                ? (tableActionsFilters.values.value as string)
                : ''
            }
            searchPlaceholder={f({ id: 'form.search' })}
            selectButton={f({ id: 'table.apply' })}
            resetButton="Reset"
            onChangeCallback={handleSearch}
          />
        </div>
      </div>
    </div>
  );
}
